import React, { useState } from 'react';
import "./WeatherApp.css";
import Particles from 'react-tsparticles';
import ParticlesConfig2 from './config/particle-config2';

const api = {
    key: "c05d7501c1da3853b5712ce9fef3a403",
    base: "https://api.openweathermap.org/data/2.5/"
  }

function WeatherApp() {
    const [query, setQuery] = useState('');
    const [weather, setWeather] = useState({});

    const search = evt => {
        if (evt.key === "Enter") {
        fetch(`${api.base}weather?q=${query}&units=imperial&APPID=${api.key}`)
            .then(res => res.json())
            .then(result => {
            setWeather(result);
            setQuery('');
            console.log(result);
            });
        }
    }
    const weatherBackground = (temp) => {
        if (temp <= 32){
            return 'app';
        } else if(temp > 32 && temp < 60) {
            return 'app chilly';
        } else {
            return 'app warm'
        }
    }

  const dateBuilder = (d) => {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    let day = days[d.getDay()];
    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();

    return `${day}, ${month} ${date}, ${year}`
  }
    return (
        <>  
            
            <div className={(typeof weather.main != "undefined") ? weatherBackground(weather.main.temp) : 'app chilly'}>
                
                {/* {(typeof weather.main != "undefined") ? weather.main.temp < 33 ? <Particles className="particles" params={ParticlesConfig2}/> : ''  : ''} */}
                
                <main>
                

                    <div className="search-box">
                    <input 
                        type="text"
                        className="search-bar"
                        placeholder="Search City..."
                        onChange={e => setQuery(e.target.value)}
                        value={query}
                        onKeyPress={search}
                    />
                    </div>
                    {(typeof weather.main != "undefined") ? (
                    <div>
                    <div className="location-box">
                        <div className="location">{weather.name}, {weather.sys.country}</div>
                        <div className="date">{dateBuilder(new Date())}</div>
                    </div>
                    <div className="weather-box">
                        <div className="temp">
                        {Math.round(weather.main.temp)}°F
                        </div>
                        <div className="weather">{weather.weather[0].main}</div>
                    </div>
                    </div>
                    ) : ('')}
                </main>
            
            </div>
            
        </>
    )
}

export default WeatherApp
