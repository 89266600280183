import React from 'react';
import { Link } from 'react-router-dom';
// import Home from './Home';


function AboutMeNavBar() {
    return (
        <nav>
            <ul id="aboutMeNav">
            <Link style={{ textDecoration: 'none' }} to='/weatherapp'>
                    <li>Weather App</li>
                </Link>
                <Link style={{ textDecoration: 'none' }} to='/todoapp'>
                    <li>Todo App</li>
                </Link>
                
                <Link style={{ textDecoration: 'none' }} to='/home'>
                    <li>Social Media App</li>
                </Link>
                {/* <Link style={{ textDecoration: 'none' }} to='/bootstrap'>
                    <li>Bootstrap</li>
                </Link> */}
                <Link style={{ textDecoration: 'none' }} to='/minesweeper'>
                    <li>Minesweeper</li>
                </Link>
            </ul>
        </nav>
    )
}

export default AboutMeNavBar
