import React from 'react';
import { NavBar } from './NavBar';

function Profile() {
    return (
        <div>
            <NavBar />
            <h1>Profile Page</h1>
        </div>
    )
}

export default Profile
