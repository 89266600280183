import React from 'react';
import './Minesweeper.css';
import Board from './Board';

function Minesweeper() {
  return <div>
      
      <Board />
  </div>;
}

export default Minesweeper;
