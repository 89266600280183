import React from 'react';
import Capitalize from './Capitalize';
import './Card.css';
import { Link } from 'react-router-dom';

function Card(props) {

    return (
        <div className='pokemonCardsContainer'>
            {props.pokemon.map((_pokemon, index) => {
                return (
                    <div className='pokemonCard'>
                        <Link to={`/pokemonInfoPage/${(index + 1)}`}>    
                            {props.pokemon.length ? <img className='pokePic' src={_pokemon.sprites.other.dream_world.front_default} alt={_pokemon.name + '_image#' + (index + 1)}></img> : null}  
                            <h3>{Capitalize(_pokemon.name)}</h3>
                        </Link>  
                    </div>
                )
            })}           
        </div>
    )
}

export default Card
