import React from 'react';
import Todo from './Todo';


function TodoList(props) {
    console.log(props.todos)
    return (
        <div class={props.todos.length > 0 ? 'todo-container' : ''}>
            <ul class="todo-list">
                {props.filteredTodos.map((todo) => {
                    return <Todo 
                        key={todo.id}
                        todo={todo} 
                        todos={props.todos} 
                        text={todo.text} 
                        setTodos={props.setTodos}/>;
                })}
            </ul>
        </div>
    );
}

export default TodoList;

