import React, { useState, useEffect } from 'react';
import './Shop.css'
import Card from './Card';
import { NavBar } from './NavBar';



function Shop() {

    const [pokemonData, setPokemonData] = useState([]);
    const [pokemon, setPokemon] = useState([])
    const [loading, setLoading] = useState('Loading...');
    const intialUrl = 'https://pokeapi.co/api/v2/pokemon?limit=151';
    

    useEffect(() => {
        fetchPokemonData();
    }, []);

    const fetchPokemonData = async() => {
        const data = await fetch(intialUrl);
        const pokemonData = await data.json();
        setPokemonData(pokemonData.results);
        setLoading('Accessing Pokedex...');
        console.log('pokemonData: ')
        console.log(pokemonData.results)

        const pokemon = await loadingPokemon(pokemonData.results);
        setPokemon(pokemon);
        console.log('pokemon')
        console.log(pokemon)
        setLoading(false);
    };

    const loadingPokemon = async(pokemonDataResults) => {
        const _pokemon = await Promise.all(pokemonDataResults.map(async function (pokemon) {
            let pokeData = await fetch(pokemon.url);
            return await pokeData.json();          
        }))
        return _pokemon;
    }

    return (
        <div>
            <NavBar />
            <div className='pokemonCardsContainer'>
            
                <img className='pokeStoreLogo' src='https://www.narita-airport.jp/img/original/3786'></img>
                <div>{loading}</div>

                <Card pokemon={pokemon}/>

                
            </div>
        </div>
    )
}

export default Shop
