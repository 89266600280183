import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

export function NavBar() {
    return (
        <nav>
            <ul className="navBar">
                <Link style={{ textDecoration: 'none' }} to='/home'>
                    <li>
                        <img src="https://img.icons8.com/fluency/50/000000/logo.png"/>
                    </li>
                </Link>
                <Link style={{ textDecoration: 'none' }} to='/home'>
                    <li>
                        {/* <img src="https://img.icons8.com/ios/50/000000/hut.png"/> */}
                        Home
                    </li>
                </Link>
                <Link style={{ textDecoration: 'none' }} to='/shop'>
                    <li>
                        {/* <img src="https://img.icons8.com/ios/50/000000/star-pokemon.png"/> */}
                        Store
                    </li>
                </Link>
                <Link style={{ textDecoration: 'none' }} to='/profile'>
                    <li>
                        {/* <img src="https://img.icons8.com/ios/50/000000/cat-profile.png"/> */}
                        Profile
                    </li>
                </Link>
                <Link style={{ textDecoration: 'none' }} to='/messages'>
                    <li>
                        {/* <img src="https://img.icons8.com/ios/50/000000/imessage.png"/> */}
                        Messages
                    </li>
                </Link>               
                
                <Link style={{ textDecoration: 'none' }} to='/'>
                    <li>
                        {/* <img src="https://img.icons8.com/ios/50/000000/guest-male.png"/> */}
                        About Me
                    </li>
                </Link>
            </ul>
        </nav>
    )
}





