import './App.css';
import { NavBar } from './NavBar';
import Home from './Home';
import Messages from './Messages';
import Profile from './Profile';
import Shop from './Shop';
import AboutMe from './AboutMe';
import PokemonInfoPage from './PokemonInfoPage';
import Practice from './Practice';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TodoApp from './TodoApp';
import WeatherApp from './WeatherApp';
import BootstapWebpage from './BootstapWebpage';
import Minesweeper from './Minesweeper';


function App() {
  
  return (
    <div>
      
      <Router>
        <div className="App">
          {/* <NavBar /> */}
          <Switch>
            <Route path="/" exact component={AboutMe} />
            <Route path="/home" component={Home} />
            <Route path="/messages" component={Messages} />
            <Route path="/profile" component={Profile} />
            <Route path="/shop" component={Shop} />
            <Route path="/pokemonInfoPage/:pokemon" component={PokemonInfoPage} />
            <Route path="/todoapp" component={TodoApp} />
            <Route path="/weatherapp" component={WeatherApp} />
            <Route path="/bootstrap" component={BootstapWebpage} />
            <Route path="/minesweeper" component={Minesweeper} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
