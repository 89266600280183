import React, { useState, useEffect } from 'react';
import { NavBar } from './NavBar';

function Practice() {
    const [pokemonData, setPokemonData] = useState([{name: ''}]);
    const [pokemonMetaData, setPokemonMetaData] = useState([]);
    const intialUrl = 'https://pokeapi.co/api/v2/pokemon?limit=151';

    useEffect(() => {
        fetchPokemonData();
    }, [])

    const fetchPokemonData = async() => {
        const response = await fetch(intialUrl);
        const pokemonData = await response.json();
        setPokemonData(pokemonData.results);
        console.log('pokemonData: ');
        console.log(pokemonData);
       

    }

    
        

    return (
        <div>
            <NavBar />
            <h1>Practice Page</h1>
            {console.log('Practice Page: Render ')}
            {pokemonData[0].name.length != 0? pokemonData[0].name: null}
        </div>
    )
}

export default Practice
