import React, { useRef, useState } from 'react';
import './Modal.css';
import firebase from './Firebase';
// const db = firebase.firestore();

function Modal({ open, onClose }) {
    const form = useRef('');
    const [isOpen, setIsOpen] = useState(true);

    // const mySubmitHandler = (event) => {
    //     event.preventDefault();
    //     db.collection('posts').add({
    //         postContent: form.current.value
    //     })
    //     form.current.value = '';
    //     onClose()   
    // }
    
    
    if (!open) return null;
    return (
        <>
            <div className='modalOverlay' />
            <div className='modalContainer'>

                
                <button className='cancelPostButton' onClick={onClose}>X</button>
                <h2 className='modalTitle'>Create Post</h2>

                {/* <form className='postForm' onSubmit={mySubmitHandler}>
                    <input className='postInput' ref={form}  type='text' name='post' placeholder="What's on your mind?"></input>
                    <button className='postButton' onSubmit={mySubmitHandler}>Post</button>
                </form> */}

                

                
              
            </div>
        </>
    )
}

export default Modal;
