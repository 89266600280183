import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
// import firebase from './Firebase';
import { NavBar } from './NavBar';
import Modal from './Modal';
import ParticleBanner from './ParticleBanner';
import HomeParticleBanner from './HomeParticleBanner';

// const db = firebase.firestore();

function Home() {
    
    const [posts, setPosts] = useState([]);
    const form = useRef('');
    const [isOpen, setIsOpen] = useState(false);

   

    // const getPosts = () => {

    //     db.collection('posts').onSnapshot((snapshot) => {
    //         const posts = []
    //         snapshot.docs.forEach(doc => {             
    //             posts.push(doc.data().postContent)
    //             console.log(doc.data().postContent)
    //             console.log('posts')
    //             console.log(posts)
    //         })
    //         setPosts(posts);
    //     })   
    // }

    // useEffect(() => {
    //     getPosts();
    // }, [])

    // const mySubmitHandler = (event) => {                I don't know if i need this
    //     event.preventDefault();
    //     db.collection('posts').add({
    //         postContent: form.current.value
    //     })
    //     form.current.value = '';
        
    // }

    return (
        <div className='homePageContainer'>
            
            <NavBar />
            {/* <div className="particleBanner">
                <HomeParticleBanner />
            </div> */}
            {/* <ParticleBanner /> */}
            
            

            



            
            <h1 style={{textAlign: "center", margin: '20px 0px 40px'}}>Home Page</h1>

            {/* <div className='postContainer'>                  I don't know if I need this
                <form className='postForm' onSubmit={mySubmitHandler}>
                    <input className='postInput' ref={form}  type='text' name='post' placeholder='Create Post'></input>
                </form>
            </div> */}

            <div className='postContainer'>
                <button className='postModalButton' onClick={() => {setIsOpen(true)}}>Create Post</button>

                <Modal open={isOpen} onClose={() => setIsOpen(false)} />
            </div>

            <ul className='timeline'>
            </ul>

            {/* {posts.map((post) => (
                <p className='timelinePosts'>{post}</p>
            ))} */}

        </div>
    )
}

export default Home
