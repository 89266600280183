import React, { useEffect, useState } from 'react';
import Square from './util/Square';


function Board() {

    const createBoard = () => {
        return <Square />
    }
    

  return <div>
      {createBoard()}
  </div>;
}

export default Board;
