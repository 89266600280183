import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Capitalize from './Capitalize';
import './PokemonInfoPage.css';
import { NavBar } from './NavBar';

function PokemonInfoPage() {
    const { pokemon } = useParams();
    const [pokemonData, setPokemonData] = useState({name: 0});

    useEffect(() => {
        fetchPokemonData();
    }, []);

    const fetchPokemonData = async() => {
        const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${pokemon}`);
        const pokemonData = await response.json();
        setPokemonData(pokemonData);
        console.log('pokemonData: ')
        console.log(pokemonData)
    }


    const metersToFeet =(meters) => {
        const feet = Math.floor(meters * 3.281);
        const inches = (Math.round(meters * 39.37))%12;
        const height = `${feet}' ${inches}"`;
        return height;
    }
   
    return (
        
        <div className='pokemonInfoPageContainer'>
            <NavBar />
            
            <h6 className='fix'>{pokemonData.name? Capitalize(pokemonData.name) : null}</h6>

            <div className='pokemonInfoPageTopContainer'>
                
                <div className="pokemonImageContainer">
                <h1 className='pokemonName'>{pokemonData.name? Capitalize(pokemonData.name) : null}</h1>
                {pokemonData.name? <img className='pokemonInitialImage' src={pokemonData.sprites.other.dream_world.front_default}></img>: null}
                </div>
               
                {pokemonData.name?
                <div className='pokemonInformationContainer'>   
                    
                    <h3>Type: <span className='stats'>{Capitalize(pokemonData.types[0].type.name)}</span></h3>
                    <h3>Weight: <span className='stats'>{ Math.round((pokemonData.weight / 10) * 2.205)}lbs</span></h3>
                    <h3>Height: <span className='stats'>{ metersToFeet(pokemonData.height / 10) }</span></h3>
                    <h3>Health: <span className='stats'>{ pokemonData.stats[0]['base_stat']}</span></h3>
                    <h3>Attack: <span className='stats'>{ pokemonData.stats[1]['base_stat']}</span></h3>
                    <h3>Defense: <span className='stats'>{ pokemonData.stats[2]['base_stat']}</span></h3>
                    <h3>Speed: <span className='stats'>{ pokemonData.stats[3]['base_stat']}</span></h3>
                    
                </div>
                : null}
            </div>
           
            {console.log(pokemon)}
        </div>
    )
   
}

export default PokemonInfoPage
