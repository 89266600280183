import React from 'react';
import './AboutMe.css';
// import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
// import Home  from './Home';
import AboutMeNavBar from './AboutMeNavBar';
import webMe from './webMe.jpg';
// import TodoList from './TodoList';

function AboutMe() {
    return (
        <div className='aboutMeContainer'>
            <div>
                
                {/* <Router>
                    <div>
                        <Switch>
                            <Route path="/home" component={Home} />
                        </Switch>
                    </div>  
                </Router> */}
                <div className='banner'>
                    <div className="title">
                        <h1 className='name'>CORBETT</h1>
                        <h2 className='name'>KNOFF</h2>
                    </div>
                    <div className='picMeContainer'>
                        <img src={webMe} className='picMe'></img>
                    </div>
                </div>
                <div className='aboutMeNavBarContainer'>
                    <AboutMeNavBar />
                </div>
            </div>

            
            
        </div>
    )
}

export default AboutMe
