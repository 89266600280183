import React, { useState, useEffect } from 'react';
import TodoForm from './TodoForm';
import TodoList from './TodoList';
import './TodoApp.css';
import TodoSidebar from './TodoSidebar';
import './TodoSidebar.css';

function TodoApp() {
    //State
    const [inputText, setInputText] = useState('');
    const [todos, setTodos] = useState([]);
    const [status, setStatus] = useState("all");
    const [filteredTodos, setFilteredTodos] = useState([]);

    //Run ONCE when the app starts
    useEffect(() => {
        getLocalTodos();
    }, []);

    //Use Effect
     useEffect(() => {
        filteredHandler();
        saveLocalTodos();
    }, [todos, status]);

    //Functions
    const filteredHandler = () => {
        switch (status){
            case 'completed':
                setFilteredTodos(todos.filter(todo => todo.completed === true ));
                break;
            case 'uncompleted':
                setFilteredTodos(todos.filter(todo => todo.completed === false));
                break;
            default:
                setFilteredTodos(todos);
                break;
        }
    }


    //Save to Local Storage
    const saveLocalTodos = () => {
        localStorage.setItem('todos', JSON.stringify(todos));     
    };

    const getLocalTodos = () => {
        if (localStorage.getItem('todos') === null) {
            localStorage.setItem('todos', JSON.stringify([]));
        } else {
            let todoLocal = JSON.parse(localStorage.getItem('todos'));
            setTodos(todoLocal);
        }
    };



    return (
        <div className="todoApp">

            {/* <TodoSidebar /> */}

            <div className="todoSection">

                <header>
                    <h1>Todo List</h1>
                </header>

                <TodoForm 
                    todos={todos} 
                    setTodos={setTodos} 
                    inputText={inputText} 
                    setInputText={setInputText}
                    setStatus={setStatus}
                />
                <div className="todoListContainer">
                    <TodoList 
                        todos={todos} 
                        setTodos={setTodos}
                        filteredTodos={filteredTodos}
                    />
                </div>
            </div>
            
        </div>
    )
}

export default TodoApp
