import React from 'react';
import { NavBar } from './NavBar';

function Messages() {
    return (
        <div>
            <NavBar />
            <h1>Messages Page</h1>
        </div>
    )
}

export default Messages
 